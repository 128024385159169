import React from "react"
import { graphql } from "gatsby"
import { Typography, Grid, Box } from "@material-ui/core"
import styled from "styled-components"
import logo from "../assets/equals-rounded.svg"
import Image from "gatsby-image"
import {
  SiteTitle,
  Section,
  Copyright,
  BackButton,
  RichText,
  Card,
  MenuDrawer,
} from "../components"
import { dark, accent } from "../theme/colors"
import BackgroundImage from "gatsby-background-image"
import theme from "../theme"

const BlendBackground = styled(BackgroundImage)`
  background-blend-mode: color;
`

const Avatar = styled(Image)`
  width: 100%;
  margin-bottom: ${theme.spacing(2)}px;
  border-radius: 5px;
`

const Logo = styled.img`
  width: 15px;
  margin-right: ${theme.spacing(2)}px;
`

const MetaGrid = styled(Grid)`
  background-color: ${accent};
  padding: ${theme.spacing(2)}px;
  border-radius: 5px;
  color: white;
  text-align: center;
  height: 100%;
`

const StoryGrid = styled(Grid)`\
  padding: ${theme.spacing(2)}px;
`

export const LedenervaringenPage = ({ data }) => {
  const {
    title,
    background,
    introduction,
    leden,
  } = data.contentfulLedenervaringenPage
  const backgrounds = [`linear-gradient(${dark}, ${dark})`, background.fluid]

  return (
    <React.Fragment>
      <MenuDrawer />
      <SiteTitle title="Ledenervaringen" />

      <Box
        display="flex"
        minHeight="100vh"
        flexDirection="column"
        overflow="hidden"
      >
        {/* Background image */}
        <BlendBackground fluid={backgrounds}>
          <Section
            slant="background"
            horizontalSpacing={3}
            verticalSpacing={10}
          >
            <BackButton to="../../" color="secondary" />
          </Section>
        </BlendBackground>

        {/* Rich Text Section */}
        <Section
          display="flex"
          flexGrow={1}
          horizontalSpacing={3}
          verticalSpacing={1.5}
        >
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={10} xl={6}>
              <Typography variant="h2" gutterBottom>
                {title}
              </Typography>
              <RichText>{introduction.json}</RichText>
            </Grid>
          </Grid>
        </Section>

        <Section horizontalSpacing={3} verticalSpacing={2} slant="dark">
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={8} xl={8}>
              {leden.map((entry) => (
                <Box mb={2} key={entry.name} horizontalSpacing={3}>
                  {entry.avatarPositie ? (
                    <Card spacing={3}>
                      <Grid spacing={3} container>
                        <MetaGrid xs={12} sm={12} md={4} xl={4} alignItems="">
                          <Avatar fluid={entry.avatar.fluid} />
                          <Typography variant="h4">{entry.name}</Typography>
                          <Typography>
                            Leeftijd: {entry.leeftijd} jaar
                          </Typography>
                          <Typography>Demosjaar: {entry.jaargang}</Typography>
                          <Typography>Studie: {entry.studie}</Typography>
                        </MetaGrid>
                        <StoryGrid xs={12} sm={12} md={8} xl={8}>
                          <Logo src={logo} />

                          <Typography variant="h4">Mijn ervaring</Typography>
                          <RichText>{entry.ervaring.json}</RichText>
                        </StoryGrid>
                      </Grid>
                    </Card>
                  ) : (
                    <Card spacing={3}>
                      <Grid spacing={3} container>
                        <StoryGrid xs={12} sm={12} md={8} xl={8}>
                          <Logo src={logo} />
                          <Typography variant="h4">Mijn ervaring</Typography>
                          <RichText>{entry.ervaring.json}</RichText>
                        </StoryGrid>
                        <MetaGrid
                          xs={12}
                          sm={12}
                          md={4}
                          xl={4}
                          alignItems="right"
                        >
                          <Avatar fluid={entry.avatar.fluid} />
                          <Typography variant="h4">{entry.name}</Typography>
                          <Typography>
                            Leeftijd: {entry.leeftijd} jaar
                          </Typography>
                          <Typography>Demosjaar: {entry.jaargang}</Typography>
                          <Typography>Studie: {entry.studie}</Typography>
                        </MetaGrid>
                      </Grid>
                    </Card>
                  )}
                </Box>
              ))}
            </Grid>
          </Grid>
        </Section>

        {/* Footer */}
        <Section color="dark" slantCenter noPaddingTop>
          <Copyright />
        </Section>
      </Box>
    </React.Fragment>
  )
}

export const query = graphql`
  query getLedenervaringPage {
    contentfulLedenervaringenPage {
      introduction {
        json
      }
      leden {
        avatarPositie
        name
        leeftijd
        jaargang
        studie
        avatar {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        ervaring {
          json
        }
      }
      title
      background {
        fluid(maxWidth: 1920, maxHeight: 1080, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default LedenervaringenPage
